import React from 'react';

const BrandLogo = () => {
  return (
    <svg
      version="1.1"
      viewBox="0.0 0.0 568.3595800524935 616.2677165354331"
      fill="none"
      stroke="none"
      strokeLinecap="square"
      strokeMiterlimit="10"
    >
      <clipPath id="p.0">
        <path
          d="m0 0l568.35956 0l0 616.2677l-568.35956 0l0 -616.2677z"
          clipRule="nonzero"
        />
      </clipPath>
      <g clipPath="url(#p.0)">
        <path
          fill="#000000"
          fillOpacity="0.0"
          d="m0 0l568.35956 0l0 616.2677l-568.35956 0z"
          fillRule="evenodd"
        />
        <path
          fill="#783f04"
          d="m274.6602 345.30594c-2.8417664 61.239502 4.822571 175.6785 -0.9145508 205.87222c-5.737152 30.193726 -36.350037 36.529602 -33.50827 -24.7099c2.8417664 -61.239502 44.821716 -312.53336 50.55887 -342.72705c5.7371216 -30.19371 -13.294281 100.32527 -16.136047 161.56474z"
          fillRule="evenodd"
        />
        <path
          stroke="#ffe599"
          strokeWidth="3.0"
          strokeLinejoin="round"
          strokeLinecap="butt"
          d="m274.6602 345.30594c-2.8417664 61.239502 4.822571 175.6785 -0.9145508 205.87222c-5.737152 30.193726 -36.350037 36.529602 -33.50827 -24.7099c2.8417664 -61.239502 44.821716 -312.53336 50.55887 -342.72705c5.7371216 -30.19371 -13.294281 100.32527 -16.136047 161.56474z"
          fillRule="evenodd"
        />
        <path
          fill="#999999"
          d="m236.7336 593.91077l0 0c0 -1.7834473 7.082962 -3.4938354 19.69072 -4.754883c12.607758 -1.2610474 29.70755 -1.9695435 47.53763 -1.9695435l0 0c37.12918 0 67.22833 3.01062 67.22833 6.7244263l0 0c0 3.7138062 -30.099152 6.7244263 -67.22833 6.7244263l0 0c-37.12918 0 -67.22835 -3.01062 -67.22835 -6.7244263z"
          fillRule="evenodd"
        />
        <path
          fill="#6aa84f"
          d="m214.19429 432.66785c-11.048721 -6.4492188 -84.16885 70.74246 -118.11813 62.144073c-33.949287 -8.598389 -90.80042 -75.82254 -85.57756 -113.734436c5.222867 -37.911896 85.57669 -96.73486 116.91476 -113.73697c31.338066 -17.002075 74.32784 19.736633 71.11364 11.724396c-3.214203 -8.012207 -63.279556 -78.558304 -90.398865 -59.79767c-27.119316 18.76062 -67.69671 125.06943 -72.31702 172.36145c-4.62031 47.291992 23.502254 87.74414 44.59515 111.39053c21.092896 23.646423 59.664207 42.213196 81.96223 30.487976c22.298004 -11.72522 62.874527 -94.39014 51.825806 -100.839355z"
          fillRule="evenodd"
        />
        <path
          stroke="#38761d"
          strokeWidth="4.0"
          strokeLinejoin="round"
          strokeLinecap="butt"
          d="m214.19429 432.66785c-11.048721 -6.4492188 -84.16885 70.74246 -118.11813 62.144073c-33.949287 -8.598389 -90.80042 -75.82254 -85.57756 -113.734436c5.222867 -37.911896 85.57669 -96.73486 116.91476 -113.73697c31.338066 -17.002075 74.32784 19.736633 71.11364 11.724396c-3.214203 -8.012207 -63.279556 -78.558304 -90.398865 -59.79767c-27.119316 18.76062 -67.69671 125.06943 -72.31702 172.36145c-4.62031 47.291992 23.502254 87.74414 44.59515 111.39053c21.092896 23.646423 59.664207 42.213196 81.96223 30.487976c22.298004 -11.72522 62.874527 -94.39014 51.825806 -100.839355z"
          fillRule="evenodd"
        />
        <path
          fill="#6aa84f"
          d="m234.51604 224.32848c7.266327 -10.758926 -71.29111 -87.160095 -60.815826 -120.705475c10.4752655 -33.545387 83.82735 -87.42916 123.667465 -80.56684c39.84012 6.862318 98.69452 89.69392 115.37329 121.740746c16.678772 32.04683 -23.897339 73.40404 -15.30069 70.54025c8.596649 -2.8638 85.59009 -59.81476 66.880554 -87.72296c-18.709534 -27.908195 -129.36276 -73.05911 -179.1377 -79.72623c-49.77495 -6.6671257 -93.665146 19.67538 -119.51196 39.72349c-25.846832 20.048111 -47.04313 57.778984 -35.568985 80.56516c11.474136 22.786163 97.14751 66.9108 104.41385 56.151855z"
          fillRule="evenodd"
        />
        <path
          stroke="#38761d"
          strokeWidth="4.0"
          strokeLinejoin="round"
          strokeLinecap="butt"
          d="m234.51604 224.32848c7.266327 -10.758926 -71.29111 -87.160095 -60.815826 -120.705475c10.4752655 -33.545387 83.82735 -87.42916 123.667465 -80.56684c39.84012 6.862318 98.69452 89.69392 115.37329 121.740746c16.678772 32.04683 -23.897339 73.40404 -15.30069 70.54025c8.596649 -2.8638 85.59009 -59.81476 66.880554 -87.72296c-18.709534 -27.908195 -129.36276 -73.05911 -179.1377 -79.72623c-49.77495 -6.6671257 -93.665146 19.67538 -119.51196 39.72349c-25.846832 20.048111 -47.04313 57.778984 -35.568985 80.56516c11.474136 22.786163 97.14751 66.9108 104.41385 56.151855z"
          fillRule="evenodd"
        />
        <path
          fill="#6aa84f"
          d="m340.72025 347.0097c-2.9128113 -9.085785 135.78403 -153.25745 166.43619 -173.00601c30.65216 -19.748566 45.216187 25.680283 17.476807 54.514618c-27.73935 28.83435 -181.00021 127.57715 -183.913 118.491394z"
          fillRule="evenodd"
        />
        <path
          stroke="#38761d"
          strokeWidth="8.0"
          strokeLinejoin="round"
          strokeLinecap="butt"
          d="m340.72025 347.0097c-2.9128113 -9.085785 135.78403 -153.25745 166.43619 -173.00601c30.65216 -19.748566 45.216187 25.680283 17.476807 54.514618c-27.73935 28.83435 -181.00021 127.57715 -183.913 118.491394z"
          fillRule="evenodd"
        />
        <path
          fill="#783f04"
          d="m166.79561 393.79507c-1.4265137 -2.8521423 116.7397 -110.62686 144.2441 -86.79004c27.504364 23.836823 1.2235413 202.3071 20.782135 229.811c19.558624 27.503967 93.105865 -59.0831 96.56958 -64.787384c3.463684 -5.7042847 -57.655304 55.21347 -75.787415 30.561676c-18.13211 -24.651794 -2.0371704 -160.33987 -33.00525 -178.47244c-30.968079 -18.132538 -151.37665 72.52933 -152.80315 69.677185z"
          fillRule="evenodd"
        />
        <path
          stroke="#fff2cc"
          strokeWidth="3.0"
          strokeLinejoin="round"
          strokeLinecap="butt"
          d="m166.79561 393.79507c-1.4265137 -2.8521423 116.7397 -110.62686 144.2441 -86.79004c27.504364 23.836823 1.2235413 202.3071 20.782135 229.811c19.558624 27.503967 93.105865 -59.0831 96.56958 -64.787384c3.463684 -5.7042847 -57.655304 55.21347 -75.787415 30.561676c-18.13211 -24.651794 -2.0371704 -160.33987 -33.00525 -178.47244c-30.968079 -18.132538 -151.37665 72.52933 -152.80315 69.677185z"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default BrandLogo;
