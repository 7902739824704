import React from 'react';
import cx from 'classNames';
import styles from './contact.module.css';
import { encode, isEmpty } from '../../utils/string';
import typographyStyles from '../../css/typography.module.css';

class ContactUs extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      firstName: '',
      lastName: '',
      emailOrPhone: '',
      hasEmptyFields: false,
      projectDescription: '',
    };
  }

  handleSubmit = e => {
    e.preventDefault();

    const {
      hasErrors,
      successfulSubmit,
      hasEmptyFields,
      ...restProps
    } = this.state;

    const hasEmptyField = Object.keys(restProps).some(key =>
      isEmpty(this.state[key])
    );
    if (hasEmptyField) {
      return this.setState({ hasEmptyFields: true });
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'projectRequest', ...restProps }),
    })
      .then(this.props.onSuccess)
      .catch(this.props.onError);
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  renderRequiredText = value => {
    const { hasEmptyFields } = this.state;
    return (
      hasEmptyFields &&
      isEmpty(value) && (
        <span className={cx(typographyStyles.redText, styles.errorMsg)}>
          Field is required
        </span>
      )
    );
  };

  render() {
    const {
      lastName,
      firstName,
      emailOrPhone,
      projectDescription,
    } = this.state;
    return (
      <form
        data-netlify="true"
        name="projectRequest"
        onSubmit={this.handleSubmit}
        className={styles.contactForm}
        data-netlify-honeypot="bot-field"
      >
        <p className={styles.hidden}>
          <label>
            Don’t fill this out if you're human: <input name="bot-field" />
          </label>
        </p>
        <input type="hidden" name="form-name" value="projectRequest" />
        <label
          htmlFor="firstName"
          className={cx(typographyStyles.whiteText, styles.inputLabel)}
        >
          <span>
            First Name
            <sup className={cx(typographyStyles.redText, styles.requiredField)}>
              *
            </sup>
          </span>
          {this.renderRequiredText(firstName)}
        </label>
        <input
          name="firstName"
          value={firstName}
          onChange={this.handleChange}
          className={cx(typographyStyles.whiteText, styles.textInputField)}
        />
        <label
          htmlFor="lastName"
          className={cx(typographyStyles.whiteText, styles.inputLabel)}
        >
          <span>
            Last Name
            <sup className={cx(typographyStyles.redText, styles.requiredField)}>
              *
            </sup>
          </span>
          {this.renderRequiredText(lastName)}
        </label>
        <input
          name="lastName"
          value={lastName}
          onChange={this.handleChange}
          className={cx(typographyStyles.whiteText, styles.textInputField)}
        />
        <label
          htmlFor="emailOrPhone"
          className={cx(typographyStyles.whiteText, styles.inputLabel)}
        >
          <span>
            Email or Phone Number
            <sup className={cx(typographyStyles.redText, styles.requiredField)}>
              *
            </sup>
          </span>
          {this.renderRequiredText(emailOrPhone)}
        </label>
        <input
          name="emailOrPhone"
          value={emailOrPhone}
          onChange={this.handleChange}
          className={cx(typographyStyles.whiteText, styles.textInputField)}
        />
        <label
          htmlFor="projectDescription"
          className={cx(typographyStyles.whiteText, styles.inputLabel)}
        >
          <span>
            Describe your project
            <sup className={cx(typographyStyles.redText, styles.requiredField)}>
              *
            </sup>
          </span>
          {this.renderRequiredText(projectDescription)}
        </label>
        <textarea
          name="projectDescription"
          value={projectDescription}
          onChange={this.handleChange}
          className={cx(typographyStyles.whiteText, styles.textInputField)}
        />
        <div data-netlify-recaptcha />
        <button
          type="submit"
          className={cx(styles.submitButton, typographyStyles.blackText)}
        >
          <strong>Submit</strong>
        </button>
      </form>
    );
  }
}

export default ContactUs;
