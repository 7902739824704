import React from 'react';
import cx from 'classNames';
import Social from '../../../Social';
import ContactUs from '../../../Contact';
import styles from './footer.module.css';
import ContactSuccess from '../../../Contact/ContactSuccess';
import ContactFailure from '../../../Contact/ContactFailure';
import typographyStyles from '../../../../css/typography.module.css';

class Footer extends React.PureComponent {
  state = {
    formSubmissionFailed: false,
    formSubmissionSuccessful: false,
  };

  onFormSuccess = () => {
    this.setState({
      formSubmissionSuccessful: true,
    });
  };

  onFormFailure = () => {
    this.setState({
      formSubmissionFailed: true,
    });
  };

  render() {
    const { formSubmissionFailed, formSubmissionSuccessful } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.contactSection}>
          {!formSubmissionFailed &&
            !formSubmissionSuccessful && (
              <>
                <h1
                  className={cx(
                    styles.contactHeading,
                    typographyStyles.whiteText
                  )}
                >
                  Ready to go digital?
                </h1>
                <ContactUs
                  onSuccess={this.onFormSuccess}
                  onError={this.onFormFailure}
                />
              </>
            )}
          {formSubmissionSuccessful && <ContactSuccess />}
          {formSubmissionFailed && <ContactFailure />}
        </div>
        <div className={styles.socialSection}>
          <div>
            <h2
              className={cx(styles.socialHeading, typographyStyles.whiteText)}
            >
              Follow Us
            </h2>
            <Social />
          </div>
          <p className={cx(styles.businessInfo, typographyStyles.whiteText)}>
            &copy; {new Date().getFullYear()} NoKagaz &middot;
            <a
              className={cx(styles.emailLink, typographyStyles.whiteText)}
              href="mailto:nokagaz@gmail.com?Subject=Project Request"
              target="_top"
            >
              nokagaz@gmail.com
            </a>
            &middot; Indore, Madhya Pradesh, India
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
