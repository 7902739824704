import React from 'react';
import cx from 'classNames';
import styles from './title.module.css';
import typographyStyles from '../../css/typography.module.css';
import BrandLogo from '../Images/BrandLogo';

const BrandTitle = ({ className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.logoContainer}>
        <BrandLogo />
      </div>
      <h1 className={cx(styles.heading, typographyStyles.greenText)}>
        NoKagaz
      </h1>
      <p className={cx(styles.tagline, typographyStyles.redText)}>
        <strong>We help you go digital!</strong>
      </p>
    </div>
  );
};

export default BrandTitle;
