import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import FBMessenger from './FBMessenger';
import { StaticQuery, graphql } from 'gatsby';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'A company focused to help people and businesses go digital for their daily, events, and operational needs.',
            },
            {
              name: 'keywords',
              content:
                'Website Design, E-Invitations, E-Phamphlets, Online Menus, Indore Digital, Business Operational Optimizations',
            },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div>
          {/* <Header /> */}
          {children}
        </div>
        <FBMessenger />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
