import React, { Component } from 'react';

class FBMessenger extends Component {
  componentDidMount() {
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src =
        'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js#xfbml=1&version=v2.12&autoLogAppEvents=1';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }

  render() {
    return (
      <>
        <div id="fb-root" />
        <div
          className="fb-customerchat"
          attribution="setup_tool"
          page_id="2318938561677168"
          theme_color="#e63462"
          logged_in_greeting="Hi! We are excited to help you go digital. Please tell us about your project."
          logged_out_greeting="Hi! We are excited to help you go digital. Please tell us about your project."
        />
      </>
    );
  }
}

export default FBMessenger;
