import React from 'react';
import cx from 'classNames';
import styles from './hero.module.css';
import BrandTitle from '../../../Branding/Title';
import typographyStyles from '../../../../css/typography.module.css';

const Hero = () => {
  return (
    <div className={styles.container}>
      <BrandTitle className={styles.brandContainer} />
      <div className={styles.introContainer}>
        <h1 className={cx(styles.heading, typographyStyles.blackText)}>
          A software company, based in{' '}
          <span className={typographyStyles.redText}>Indore</span>, focused on
          helping businesses and individuals go{' '}
          <span className={typographyStyles.greenText}>digital</span> for their
          formal and informal needs.
        </h1>
      </div>
    </div>
  );
};

export default Hero;
