import React from 'react';
import cx from 'classNames';
import ServiceCard from './ServiceCard';
import styles from './services.module.css';
import typographyStyles from '../../../../css/typography.module.css';

const Services = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.serviceCards}>
          <ServiceCard
            className={styles.serviceCard}
            top={
              <h2
                className={cx(
                  styles.serviceCardHeading,
                  typographyStyles.redText
                )}
              >
                Starting a new business or launching a new product?
              </h2>
            }
            bottom={
              <>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  Launching a new business or product requires{' '}
                  <strong>
                    market analysis, product validation, branding, packaging,{' '}
                  </strong>
                  and many little things which eventually tie to a{' '}
                  <strong>perfect launch</strong>. Having someone alongside
                  during this journey can create a fruitful impact towards your
                  success.
                </p>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  Let us help you leave the shore, so you can sail towards
                  success.
                </p>
              </>
            }
          />

          <ServiceCard
            className={styles.serviceCard}
            top={
              <h2
                className={cx(
                  styles.serviceCardHeading,
                  typographyStyles.redText
                )}
              >
                Is your business scaling at the expected pace?
              </h2>
            }
            bottom={
              <>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  Analyzing resource utilization and identifying repetitive
                  tasks can determine bottlenecks in business processes. Having
                  someone with expertise to identify and resolve such
                  bottlenecks can impact your scalability.
                </p>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  Let us help you <strong>automate and optimize</strong> your
                  business processes, so you can <strong>scale</strong> at the
                  desired pace and <strong>focus</strong> on things that need
                  your unbiased attention.
                </p>
              </>
            }
          />

          <ServiceCard
            className={styles.serviceCard}
            top={
              <h2
                className={cx(
                  styles.serviceCardHeading,
                  typographyStyles.redText
                )}
              >
                Is your business ready to go global?
              </h2>
            }
            bottom={
              <>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  In this internet era, having an online business identity and
                  presence is key to success. Having someone with an expertise
                  in developing performant and attractive applications can bring
                  global customers for your business.
                </p>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  Let us help you create a strong online identity and presence
                  by{' '}
                  <strong>
                    developing a modern website and mobile application
                  </strong>
                  .
                </p>
              </>
            }
          />

          <ServiceCard
            className={styles.serviceCard}
            top={
              <h2
                className={cx(
                  styles.serviceCardHeading,
                  typographyStyles.redText
                )}
              >
                Is your business social?
              </h2>
            }
            bottom={
              <>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  Social platforms like, Facebook, Instagram, and Twitter can
                  target broader audiences and solidify foundations of an online
                  presence. Having someone alongside with expertise with these
                  social platforms can result in organic growth and success for
                  your business.
                </p>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  Let us help you in managing your social{' '}
                  <strong>
                    business identity, marketing campaigns, and promotions
                  </strong>
                  .
                </p>
              </>
            }
          />

          <ServiceCard
            className={styles.serviceCard}
            top={
              <h2
                className={cx(
                  styles.serviceCardHeading,
                  typographyStyles.redText
                )}
              >
                Are you reviewing your reviews and collecting feedbacks?
              </h2>
            }
            bottom={
              <>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  Knowing how well are you doing with your customers can help
                  you create a strong brand identity, customer loyalty, and make
                  the right business decisions.
                </p>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  Let us provide you with <strong>software solutions</strong>{' '}
                  that promote customer feedbacks and reviews. Reviewing reviews
                  and incorporating feedbacks can guide a business to success.
                </p>
              </>
            }
          />

          <ServiceCard
            className={styles.serviceCard}
            top={
              <h2
                className={cx(
                  styles.serviceCardHeading,
                  typographyStyles.redText
                )}
              >
                Are you organizing an event?
              </h2>
            }
            bottom={
              <>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  Inviting guests and organizing events can get cumbersome and
                  lead to a lot of stress before your big day. Having someone
                  with expertise to manage these things can turn into a
                  blessing.
                </p>
                <p
                  className={cx(
                    typographyStyles.blackText,
                    styles.servicePoints
                  )}
                >
                  Let us provide you with digitized solutions for these
                  time-consuming tasks by utilizing{' '}
                  <strong>
                    e-invitations, e-flyers, and guest list management
                  </strong>
                  .
                </p>
              </>
            }
          />
        </div>
      </div>
    </>
  );
};

export default Services;
