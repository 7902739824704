import React from 'react';
import styles from './social.module.css';
import Twitter from '../Images/Twitter';
import Facebook from '../Images/Facebook';
import Instagram from '../Images/Instagram';

const Social = () => {
  return (
    <div className={styles.iconsContainer}>
      <div className={styles.socialIcon}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/nokagaz"
        >
          <Instagram />
        </a>
      </div>
      <div className={styles.socialIcon}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://fb.me/nokagaz"
        >
          <Facebook />
        </a>
      </div>
      <div className={styles.socialIcon}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.twitter.com/nokagaz"
        >
          <Twitter />
        </a>
      </div>
    </div>
  );
};

export default Social;
