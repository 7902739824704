import React from 'react';
import Layout from '../components/Layout';
import HomeSections from '../components/PageSections/Home';
import styles from '../components/PageSections/Home/index.module.css';

const IndexPage = () => (
  <Layout>
    <div className={styles.visualContainer}>
      <div className={styles.rightVisual} />
      <div className={styles.leftVisual} />
    </div>
    <div className={styles.topStripe} />
    <HomeSections.Hero />
    <HomeSections.Services />
    <div className={styles.bottomStripe} />
    <HomeSections.Footer />
  </Layout>
);

export default IndexPage;
