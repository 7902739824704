import React from 'react';
import cx from 'classNames';
import styles from './contact.module.css';
import typographyStyles from '../../css/typography.module.css';

const ContactFailure = () => {
  return (
    <div className={styles.contactFailure}>
      <h1 className={cx(styles.failureHeading, typographyStyles.whiteText)}>
        Oops, something went wrong!
      </h1>
      <h1 className={cx(styles.failureHeading, typographyStyles.whiteText)}>
        Please try to reach us directly at{' '}
        <a
          className={cx(styles.emailLink, typographyStyles.whiteText)}
          href="mailto:nokagaz@gmail.com?Subject=Project Request"
          target="_top"
        >
          nokagaz@gmail.com
        </a>
      </h1>
    </div>
  );
};

export default ContactFailure;
