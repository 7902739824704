import React from 'react';
import cx from 'classNames';
import styles from './servicecard.module.css';

const ServiceCard = ({ top, bottom, className }) => {
  return (
    <div className={cx(styles.container, className)}>
      {top}
      {bottom}
    </div>
  );
};

export default ServiceCard;
