import React from 'react';
import cx from 'classNames';
import styles from './contact.module.css';
import typographyStyles from '../../css/typography.module.css';

const ContactSuccess = () => {
  return (
    <div className={styles.contactSuccess}>
      <h1 className={cx(styles.successHeading, typographyStyles.greenText)}>
        Thank you for submitting your request.
      </h1>
      <h1 className={cx(styles.successHeading, typographyStyles.greenText)}>
        We will respond to your request as soon as possible.
      </h1>
    </div>
  );
};

export default ContactSuccess;
